.data_table {
  background: white;
  border-radius: 0;

  .table_header {
    text-transform: uppercase;
    color: #888b8d;
    font-weight: bold;
  }
}
